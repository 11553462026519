<script setup lang="ts">
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
</script>

<template>
  <LayoutTopBar />
  <header
    class="shadow sticky top-0 bg-scheppach-shades-0 text-scheppach-primary-700 z-1000 h-[90px] flex items-center"
  >
    <NuxtLink :to="formatLink('/')" class="mx-auto">
      <img src="/logo-scheppach.svg" alt="logo" class="h-14.5" />
    </NuxtLink>
  </header>
</template>
